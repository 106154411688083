<template>
  <div class="country">
    <v-row justify="space-between" class="pt-10">
      <v-btn elevation="5" to="/" >
        <v-icon class="mr-2"> mdi-arrow-left </v-icon>
        Back
      </v-btn>
    </v-row>

    <v-row v-if="countryDetail" :key="this.country" justify="space-between" class="pt-10">
      <v-col class="pa-10 d-flex justify-center" cols="12" lg="6" md="6" sm="12">
        <v-img aspect-ratio="1.5" max-width="500px" max-height="250px" v-bind:src="countryDetail.flags.png"></v-img>
      </v-col>
      <v-col class="pa-10 text-left" cols="12" lg="6" md="6" sm="12">
        <v-row>
          <v-col cols="12" lg="12">
            <h1 class="pb-5">{{ countryDetail.name.common }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="6">
            <p><b class="pr-3">Native Name:</b>
            {{ countryDetail.name.nativeName[myMainLanguage(countryDetail)].common }}
            </p>
            <p><b class="pr-3">Population:</b>{{ numberWithCommas(countryDetail.population) }}</p>
            <p><b class="pr-3">Region:</b>{{ countryDetail.region }}</p>
            <p><b class="pr-3">Sub Region:</b>{{ countryDetail.subregion }}</p>
            <p><b class="pr-3">Capital:</b>{{ countryDetail.capital[0] }}</p>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6">
            <p><b class="pr-3">Top level Domain:</b>{{ countryDetail.tld[0] }}</p>
            <p><b class="pr-3">Currencies:</b>{{ myCurrency(countryDetail) }}</p>
            <p><b class="pr-3">Language:</b>
              <span class="no-commas-on-last-child">
                <span v-bind:key="index" v-for="(language, index) in countryDetail.languages">{{ language }}</span>
              </span>
            </p>
          </v-col>
        </v-row>
        <br>
        <br>
        <v-row>
          <v-col cols="12" sm="4" class="pr-3">Border Countries:</v-col> 
          <v-col cols="12" sm="8">
            <span v-bind:key="index" v-for="(btnCountrySingular, index) in btnCountryDetailName">
              <v-btn elevation="5" :to="{ name: 'country', params: { country: btnCountrySingular }}" class="mr-1 mb-5">
                {{ btnCountrySingular }}
              </v-btn>
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CountryView",
  data() {
    return {
      countryDetail: undefined,
      btnCountryDetailName: [],
    };
  },
  props: {
    country: {
      type: String,
      required: true,
    },
  },
  watch: {
    // call again the method if the route changes
    $route() {
      this.pageLoad()
    },
  },
  methods: {
    pageLoad: function (country) {
    
      country = this.$route.params.country;
      this.btnCountryDetailName = [];

      axios.get(`https://restcountries.com/v3.1/name/${country}`)
      .then((res) => {
          this.countryDetail = res.data[0];

          for(let i = 0; i < res.data[0].borders.length; i++) {
          axios.get(`https://restcountries.com/v3.1/alpha/${res.data[0].borders[i]}`)
            .then((res) => {
              res.data[0].name.common; 
              this.btnCountryDetailName = [...this.btnCountryDetailName, res.data[0].name.common];
            })
            .catch((err) => console.log(err))
          }

      })
      .catch((err) => console.log(err));
    },
    myMainLanguage: function (countryDetail) {

      var mainLanguage;

      // console.log(mainLanguage);
      
      mainLanguage = Object.keys(countryDetail.languages)[0];

      // alert(mainLanguage);
      return mainLanguage;
    },
    myCurrency: function (countryDetail) {

      var mainCurrency;

      // console.log(mainLanguage);
      
      mainCurrency = Object.keys(countryDetail.currencies)[0];

      mainCurrency = countryDetail.currencies[mainCurrency].name;

      // alert(mainLanguage);
      return mainCurrency;
    },
    numberWithCommas: function (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  created(country) {

    this.pageLoad()

  },
};
</script>

<style scoped>
</style>