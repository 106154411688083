<template>
  <div class="home">
    <!-- <div class="se-pre-con"></div> -->
    <!-- <input type="hidden" id="testjquery" value="1234"> -->
    <!--  px-10  -->
    <v-row justify="space-between" class="pt-10">
      <v-col cols="12" md="5">
        <v-text-field id="country_keyword" filled solo label="Search for a country" v-model="keyword" prepend-inner-icon="mdi-magnify" @input="filterName" ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" offset-md="4">
        <!-- <v-combobox filled solo></v-combobox> -->
        <v-select id="region_select" v-if="regionList" v-model="select" filled solo :items="regionList" label="Filter By Region" @change="filterRegion" >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <!-- pa-10 -->
      <v-col class="" v-bind:key="index" v-for="(countryItem, index) in countryList" cols="12" lg="3" md="4" sm="6">
        <CountryCard  v-bind:countryItem="countryItem" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import $ from "jquery";
import CountryCard from "../components/CountryCard";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    CountryCard,
  },
  data() {
    return {
      // msg: 'Hello'
      countryList: [],
      regionList: ['View All', 'Africa', 'Americas', 'Asia', 'Europe', 'Oceania'],
      selectedKeyword: undefined,
      selectedRegion: undefined,
      keyword: null,
      select: null,
    };
  },
  methods: {
    // filtering(keyword,selectedRegion) {
    //     alert(keyword,selectedRegion);
    //     axios.get(`https://restcountries.com/v3.1/region/${selectedRegion}/name/${keyword}`)
    //     .then((res) => (this.countryList = res.data))
    //     .then(this.keyword = "")
    //     .catch(err => console.log(err));

    // },
    filterRegion(selectedRegion) {

      if(selectedRegion == 'View All'){
        
        axios.get("https://restcountries.com/v3.1/all")
        .then((res) => (this.countryList = res.data))
        .catch((err) => console.log(err));

      }else{
      
        axios.get(`https://restcountries.com/v3.1/region/${selectedRegion}`)
        .then((res) => (this.countryList = res.data))
        .then(this.keyword = "")
        .catch(err => console.log(err));
      }
    },
    filterName(keyword) {
      axios.get(`https://restcountries.com/v3.1/name/${keyword}`)
      .then((res) => (this.countryList = res.data))
      .then(this.select = "")
      .catch(err => console.log(err));
      
    },
    pageLoad() {
      
    }

  },
  created() {
    // this.pageLoad()
    axios.get("https://restcountries.com/v3.1/all")
      .then((res) => (this.countryList = res.data))
      // .then($(".se-pre-con").fadeOut("slow"))
      .catch((err) => console.log(err));

      
    // $(window).on("load", function(){
      // Animate loader off screen
      // setTimeout(
      //   function() {
      //     $(".se-pre-con").fadeOut("slow");
      //   }
      //   , 1000);
      
    // })
  },
};

</script>

<style scoped>
/* .se-pre-con {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(https://i.pinimg.com/originals/a2/de/bf/a2debfb85547f48c3a699423ba75f321.gif) center no-repeat #fff;
} */
</style>
