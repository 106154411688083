<template>
  <small>
    <!-- <router-link> -->
      <!--  to="/about/{{countryItem.name}}" -->
      <v-card :to="{ name: 'country', params: { country: countryItem.name.common }}">
      
        <v-img aspect-ratio="1.5" lazy-src="https://c.tenor.com/64BYBgDG41QAAAAC/loading.gif" transition="true" v-bind:src="countryItem.flags.png" height="200px" class="image-fit"></v-img>

        <v-card-title> {{ countryItem.name.common }} </v-card-title>

        <v-card-text class="text-left">
          <p>
            <span><b>Population:</b> {{ countryItem.population }}</span><br />
            <span><b>Region:</b> {{ countryItem.region }}</span><br />
            <span class="d-flex no-commas-on-last-child">
                <b>Capital:</b> 
                <span v-bind:key="index" v-for="(countryCapital, index) in countryItem.capital" class="ml-2">
                    <span>{{ countryCapital }}</span>
                </span>
            </span>
          </p>
        </v-card-text>
      </v-card>
    <!-- </router-link> -->
  </small>
</template>

<script>
export default {
  data: () => ({
    show: false,
  }),
  // accepted data from parent
  props: ["countryItem"],
};
</script>

<style scoped>
.image-fit{
  object-fit: contain;
}
</style>