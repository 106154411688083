<template>
  <div id="app">
    <v-app>
      <!-- <v-navigation-drawer app>
        
      </v-navigation-drawer> -->

      <v-app-bar app>
        <v-container fluid class="container-max-width pa-sm-10">
          <v-row justify="space-between"> 
            <h3 class="d-flex align-center"><router-link class="text-decoration-none" to="/" style="text-decoration: none; color: inherit;">Where in the world?</router-link></h3>
            <v-btn depressed @click="changeTheme" v-if="$vuetify.theme.dark">
              <v-icon class="mr-2"> mdi-weather-night </v-icon>
              Dark Mode
            </v-btn>
            <v-btn depressed @click="changeTheme" v-if="!$vuetify.theme.dark">
              <v-icon class="mr-2"> mdi-weather-sunny </v-icon>
              Light Mode
            </v-btn>
            <!-- <h4>
              <v-icon> mdi-weather-night </v-icon>
              Dark Mode
            </h4> -->
          </v-row>
        </v-container>
      </v-app-bar>

      <!-- Sizes your content based upon application components -->
      <v-main app>
        <!-- Provides the application the proper gutter -->
        <v-container fluid class="container-max-width pa-10">
          <!-- If using vue-router -->
          <!-- <router-view></router-view> -->
          <!--
          <nav>
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
          </nav>
          -->
          <!-- <v-row justify="space-around">
            <v-icon large color="green darken-2"> mdi-domain </v-icon>
          </v-row> -->
          <router-view />
        </v-container>
      </v-main>

      <v-footer app>
        <!-- -->
      </v-footer>
    </v-app>
  </div>
</template>

<script>
// import vuetify from './plugins/vuetify';
export default {
  name: 'app',
  data() {
    return {
      value: undefined,
    };
  },
  methods: {
    
    changeTheme() {

      // console.log(vuetify.userPreset.theme.dark);

      // value = vuetify.userPreset.theme.dark;
      
      // vuetify.userPreset.theme.dark = !vuetify.userPreset.theme.dark;

      // vuetify.userPreset.theme.dark = !vuetify.userPreset.theme.dark;

      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      
    },

  },
  // vuetify.theme.dark = false;
}
</script>

<style lang="scss">
#app {
  // Family: [Nunito Sans](https://fonts.google.com/specimen/Nunito+Sans)
  // font-family: 'Nunito Sans', sans-serif;
  // font-family: Nunito, sans-serif;
  font-family: Nunito, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}

.container-max-width {
  max-width: 1440px !important;
}

.w-100 {
  width: 100%;
}

.list-style-none {
  list-style-type:none;
}

.no-commas-on-last-child > span:not(:last-child):after {
  content: ', ';
}

// v-main {
//   max-width: 1440px;
// }

// v-main {
//   max-width: 1440px;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
